import {defineStore} from 'pinia';
import axios from "axios";

let originalSetInterval = window.setInterval;
let originalSetTimeout = window.setTimeout;
let intervalTimerList = [];
let timeOutTimerList=[]


export const viewData = defineStore('viewData', {
    state: () => {
        return {
            isLogin: false,
            isMobile: true,
            isTablet: false,
            showSubMenu: true,
            currentUser: {},
            roleList: [],
            applicationUser: {},
            pageAreaName: '',
            pageTitle: '',
            menuTitle: '',
            mainMenuName: '',
            showDrawer: false,
            drawerName: '',
            drawerTitle: '',
            drawerWidth: 600,
            drawerParameter: {},
            currentPage: null,
            currentDrawer: null,
            currentFieldLat:22.922222,
            currentFieldLon:120.289999,
            selectFieldList: [],
            dataFieldList: [],
            caseEquipmentList:[],
            selectTargetId:'',
            searchKeyword: "",
            ssoUrl: '',
            showToggleSplitPanelSizeButton: false,
            splitPanelSize: 0.225,
            splitPanelSizeTemp: 0.225,

            fieldList:[{
                "field_id": 1,
                "field_name": "綠能科技示範場域",
            },{
                "field_id": 2,
                "field_name": "新化溫室",
            }],
            currentFieldId: 1,
            ws: null,
            dataPool: {},
        }
    },
    getters: {
        currentField(){
            try {
                return this.fieldList.filter(x=>x.field_id === this.currentFieldId)[0]
            }catch (e){
                return {
                    "field_id": 1,
                    "field_name": "綠能科技示範場域",
                }
            }
        },
    },
    actions: {
        wsConnect(){
            let v = this;
            let ws = new WebSocket(`wss://conn-dot-greenshepherdcomtw.appspot.com/conn`);
            ws.onopen = function () {
                console.log('ws connected')
            };
            ws.onmessage = function (e) {
                v.dataPool = Object.assign({}, v.dataPool, JSON.parse(e.data));
            };
            ws.onclose = function (e) {
                console.log('ws closed')
                console.log(e)
            };
            this.ws = ws
        },
        wsSend(message){
            if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                // 只有当 WebSocket 处于 OPEN 状态时才发送消息
                this.ws.send(message);
            } else {
                // 连接还未建立，可以在这里处理等待或者错误处理逻辑
                console.log("WebSocket is not open. Message not sent.");
            }
        },
        showData(key) {
            // 將鍵分解成層次
            const keys = key.split('.');
            // 從 dataPool 開始深入尋找
            let currentLevel = this.dataPool;

            // 迭代所有層次來獲取最終值
            for (let i = 0; i < keys.length; i++) {
                if (currentLevel[keys[i]] !== undefined) {
                    // 移動到下一層級
                    currentLevel = currentLevel[keys[i]];
                } else {
                    // 如果在任何點上找不到鍵，返回 0.0
                    return 0.0;
                }
            }
            // 返回找到的值，或者如果最後的層次不是數字，返回 0.0
            return typeof currentLevel === 'number' ? currentLevel : 0.0;
        },
        hasRole(role){
            return this.roleList.includes(role)
        },
        getFormData(object) {
            let formData = new FormData();
            Object.keys(object).forEach(key => formData.append(key, object[key]));
            return formData;
        },
        handleWindowResize() {
            this.windowWidth = window.innerWidth;
            try{
                this.drawerWidth = window.innerWidth * 0.8;
                if (window.innerWidth >= 969) this.drawerWidth = window.innerWidth * 0.6;
                if (window.innerWidth >= 1440) this.drawerWidth = window.innerWidth * 0.4;
            }catch(err){
                console.log(err);
            }
            const tabletWidthThreshold = 1440;
            if (this.windowWidth <= tabletWidthThreshold) {
                this.showSubMenu = false;
                this.isTablet = true
            }else{
                this.showSubMenu = true;
                this.isTablet = false
            }
            const mobileWidthThreshold = 769;
            if (this.windowWidth <= mobileWidthThreshold) {
                this.isMobile = true
                this.splitPanelSize = 0.001
            }else{
                this.isMobile = false
                this.splitPanelSize = 250 / window.innerWidth;
            }
        },
        openDrawer(drawerName, drawerTitle, drawerParameter) {
            drawerTitle = drawerTitle || ''
            drawerParameter = drawerParameter || {}
            this.showDrawer = true
            this.drawerTitle = drawerTitle
            this.drawerParameter = drawerParameter
            setTimeout(() => {
                this.drawerName = drawerName
            }, 330)
        },
        setPageInterval(callback, ms, keep = false){
            keep = keep || false
            let i = originalSetInterval(callback, ms);
            if (!keep) intervalTimerList.push(i);
        },
        setPageTimeout(callback, ms, keep = false){
            keep = keep || false
            let i = originalSetTimeout(callback, ms);
            if (!keep) timeOutTimerList.push(i);
            return i
        },
        clearPageInterval (){
            intervalTimerList.forEach(i=>{clearInterval(i);});
            intervalTimerList = [];
        },
        clearPageTimeout(){
            timeOutTimerList.forEach(i=>{clearTimeout(i);});
            timeOutTimerList=[];
        },
        async beforePageChange() {
            this.clearPageInterval()
        },
        afterPageChange() {
            let path = location.pathname
            let pageName = path.split('/').join('_').substring(1)
            if (pageName.length === 1) pageName = 'components_' + pageName
            this.pageAreaName = pageName
            setTimeout(() => {
                this.mainMenuName = pageName.split("_")[0]
            }, 30)
        },
        ssoCheck(){
            let v = this
            return new Promise((resolve, reject) => {
                let p = location.hostname
                if (p === 'localhost' || p === '127.0.0.1'){
                    p = `${location.protocol}//${location.hostname}:${location.port}`
                    if (p.endsWith(':')) p = p.substring(0, p.length - 1)
                }else{
                    p = p.replace('.greenshepherd.com.tw', '')
                }

                axios.get(`/api/sso/check?service=${encodeURI(p)}`).then(res => {
                    v.isLogin = res.data.isLogin
                    v.currentUser = res.data.data
                    if (!v.currentUser) v.currentUser = {'roles': []}
                    if (v.currentUser.user_roles) v.currentUser.roles = v.currentUser.user_roles.split(',')
                    v.ssoUrl = res.data.url
                    v.ssoReady = v.ssoUrl !== ''
                    resolve(res);
                }).catch(err => reject(err));
            });
        },
        doSelect(f){
            this.currentFieldId = f.field_id;
        },
        loadFieldData(){
            return new Promise((resolve, reject) => {
                let url = '/api/field/current?size=99999'
                axios.get(url).then((res) => {
                    let d = res.data.data
                    if (Array.isArray(d)) this.dataFieldList = d
                    this.dataFieldList.forEach(x => {
                        x.city = x.field_address.substr(0, 3)
                        x.name = x.field_name
                        x.id = x.field_id
                        x.kind = x.field_kind
                        x.address = x.field_address
                        x.lat_lng = x.field_lat_lng
                    })
                    console.log(this.dataFieldList)
                    let dataField = this.dataField
                    this.selectFieldList = dataField.filter(x => x.id)
                    this.loadTDName()
                    resolve()
                }).catch(err => reject(err))
            })
        },
        loadTDName(){
          let formData={}
          if(this.selectTargetId==''){
            let fieldList=""
            this.dataFieldList.forEach(x=>{
              fieldList+=x.id+','
            })
            fieldList=fieldList.substring(0,fieldList.length-1)
            let where={
              field_id:fieldList
            }
            formData=where
          }
          else{
            let fieldList=this.selectTargetId
            let where={
              field_id:fieldList
            }
            formData=where
          }
          console.log(formData)
          let a=this.getFormData(formData)
          axios.post(`/api/field/case_equipment_data_name`,a).then(res=>{
            console.log(res.data)
            this.caseEquipmentList=res.data.data
          })
        },
        toggleSplitPanelSize(){
            if (this.splitPanelSize > 0.1){
                this.splitPanelSize = 0
            }else{
                this.splitPanelSize = 1
            }
        }
    },
})

export default viewData