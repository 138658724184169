export {pageData};

let pageData = {
    'login':[
        {
            title: '登入',
            name: 'user',
            items: [
                {dir: 'user', title: '登入頁面', page: 'Login'},
            ]
        }
    ],
    'nav': [
        {
            title: '系統',
            name: 'system',
            items: [
                {dir: 'system', title: '儲能系統', page: 'essStatus'},
                {dir: 'system', title: '沼氣發電', page: 'gasStatus'},
                {dir: 'system', title: '太陽光電', page: 'pvStatus'},
                {dir: 'system', title: '熱泵系統', page: 'hpStatus'},
            ]
        },
        {
            title: '預測',
            name: 'predict',
            items: [
                {dir: 'predict', title: '發電預測', page: 'pv'},
                {dir: 'predict', title: '負載預測', page: 'load'},
                {dir: 'predict', title: '天氣預測', page: 'weather'},
            ]
        },
        {
            title: '調度',
            name: 'dispatch',
            items: [
                {dir: 'dispatch', title: '調度排程', page: 'calcStrategy'},
                {dir: 'dispatch', title: '調度指令', page: 'sendCommand'},
                {dir: 'dispatch', title: '多能源調度', page: 'simulation'},
                {dir: 'dispatch', title: '能源調度策略', page: 'plot'},
                {dir: 'dispatch', title: '調度記錄頁面', page: 'history'},
            ]
        },
    ],
    'drawer': [
        {dir: 'drawer', title: '圖表', page: 'Chart'},
        {dir: 'drawer', title: '派工單詳情', page: 'DispatchDetail'},
        {dir: 'drawer', title: '發電機詳情', page: 'GeneratorDetail'},
    ],
    'page':[
        {dir: 'dispatch', title: '建立派工單', page: 'CreateDispatch'},
        {dir: 'bulletin', title: '建立維修通報', page: 'CreateIssue'},
        {dir: 'completed', title: '處理結果回報',page: 'CreateReport',},
        {dir: 'bulletin', title: '案場維運',page: 'FieldMaintenance',},
        {dir: 'user', title: '通知', page: 'userNotify'},
        // {dir: 'user', title: '設定', page: 'Setting'},
        // {dir: 'user', title: '測試用', page: 'testPageChange'},
    ],
}
