import {createApp, defineAsyncComponent} from 'vue'
import axios from 'axios'
import {createRouter, createWebHistory} from 'vue-router'
import VueAxios from 'vue-axios'
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import App from './App.vue'
import './registerServiceWorker'
import moment from "moment"
import mitt from "mitt";
import dataProxy from '@/plugins/dataProxy'
import {viewData} from '@/plugins/view'
import VXETable from 'vxe-table'
import {createPinia} from 'pinia'
import 'vxe-table/lib/style.css'
import {pageData} from '@/operating.js'
import {Chart} from 'chart.js';
import 'chartjs-adapter-moment';
import './assets/css/app.css';

// Chart.register(...registerables);
moment.locale('zh-tw');

const pinia = createPinia()
const app = createApp(App)

app.use(pinia)

const bus = mitt()
const vs = viewData()

let viewEtpUser={}

let routes = []
function loadComponent(page, title, dir){
    if (typeof page === "undefined") return false
    dir = dir || 'page'
    let component = defineAsyncComponent(() =>
        import(`@/page/${dir}/${page}.vue`)
    )
    routes.push({
        path: `/${dir}/${page}`, component: component
    })
    app.component(dir + '_' + page, component)
}

const fs = require('fs');
function saveData(data_arr,data_name){
    const data = JSON.stringify(data_arr)
    window.localStorage.setItem('arr', data);
    console.log(JSON.parse(window.localStorage.getItem('arr')))
    try { fs.writeFileSync(data_name, data, 'utf-8'); }
    catch(e) { alert('Failed to save the file !');console.log(e) }
}

Object.keys(pageData).forEach(key=>{
    let d = pageData[key];
    d.forEach(i=>{
        if (!i.dir) i.dir = key
        loadComponent(i.page, i.title, i.dir)
        if (i.items && i.items.length){
            i.items.forEach(j=>{
                if (!j.dir) j.dir = key
                loadComponent(j.page, j.title, j.dir)
            })
        }
    });
});

function getFormData(object) {
    let formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
}

function numberToLocaleString(number){
    return number.toLocaleString()
}

export function viewEtpUserToDo(t, etpUser={}){
    if(t==='get') return viewEtpUser
    else if(t==='set'){
        viewEtpUser=etpUser
        console.log(viewEtpUser)
    }
}

const router = createRouter({
    history: createWebHistory(),
    routes,
})
router.beforeEach(async (to, from) => {
    return vs.beforePageChange(to, from)
})
router.afterEach((to, from, failure) => {
    if (!failure) vs.afterPageChange()
})


app
    .use(VueAxios, axios)
    .use(ViewUIPlus)
    .use(router)
    .use(dataProxy)
    .use(VXETable)

app.config.globalProperties.Chart = Chart
app.config.globalProperties.$moment = moment
app.config.globalProperties.pageData = pageData
app.config.globalProperties.view = vs
app.config.globalProperties.viewStore = vs
app.config.globalProperties.$saveData = saveData
app.config.globalProperties.$getFormData=getFormData
app.config.globalProperties.eventBus = bus
app.config.globalProperties.$numToLocale=numberToLocaleString
app.config.globalProperties.$viewEtpUserToDo=viewEtpUserToDo
app.mount('#app')