<template>
    <div id="layout" :class="{'isTablet': view.isTablet, 'isMobile': view.isMobile, 'loginPage': !view.isLogin}">
        <div id="nav">
            <div id="logo" @click="logoClick"></div>
            <div class="title" @click="logoClick" v-show="!view.isTablet">
                智能調度
            </div>
            <span class="edpStatus" :title="edpText" :class="edpStatus">{{ edpSeconds }} s</span>
            <span class="debugMode" v-if="view.debugMode">開發模式</span>
            <div class="menus">
                <div v-for="k in navData" class="mainMenuItem"
                     @click="mainMenuSelect($event, k)"
                     @mouseover="mainMenuMouseOver($event, k)"
                     :class="{active: k.name === view.mainMenuName}" :key="k.name">
                    {{ k.title }}
                </div>
                <div class="apps" @click.stop.prevent="showApps = true">
                    <Icon type="ios-apps"/>
                </div>
            </div>
        </div>
        <Sider id="sidebar" class="subMenu" v-show="view.showSubMenu">
            <div class="fieldSelectorArea">
                <Button class="fieldSelectorButton" @click="toggleSelector()" v-if="view.isLogin && view.currentField">
                    {{ view.currentField.field_name }}
                </Button>
            </div>
            <List>
                <router-link class="ivu-list-item" :class="{'active': view.pageAreaName === i.dir + '_' + i.page}"
                             :to="`/${i.dir}/${i.page}`" :key="i.page" v-for="i in menuData">{{ i.title }}
                </router-link>
            </List>
        </Sider>
        <div class="mobileMenu" v-show="view.isTablet && view.showSubMenu" :style="{right: menuRightSideWidth, height: menuHeight}">
            <List>
                <router-link @click="view.showSubMenu = false" class="ivu-list-item" :class="{'active': view.pageAreaName === i.dir + '_' + i.page}"
                             :to="`/${i.dir}/${i.page}`" :key="i.page" v-for="i in menuData">{{ i.title }}
                </router-link>
            </List>
        </div>
        <Button class="toggle-split-panel-size-button" v-show="view.showToggleSplitPanelSizeButton"
                @click="view.toggleSplitPanelSize"><Icon type="ios-arrow-back" /></Button>

        <Drawer :title="view.drawerTitle" :width="view.drawerWidth" :closable="false" v-model="view.showDrawer"
                @on-close="view.drawerName='undefined'">
            <component v-bind:is="view.drawerName"
                       :drawerTitle="view.drawerTitle"
                       :drawerParameter="view.drawerParameter"
                       :drawerName="view.drawerName" :openDrawer="view.openDrawer">
            </component>
        </Drawer>

        <Layout id="content">
            <div class="subMenuSelectorMask" v-show="view.isMobile && view.showSubMenu" @click="view.showSubMenu = false"></div>
            <div v-show="view.isLogin">
                <router-view v-if="isRouterAlive"></router-view>
            </div>
            <SSODialog v-if="!view.isLogin" @update:ssoCheck="userInit"></SSODialog>
        </Layout>

        <div class="fieldSelectorMask" v-show="showSelector" @click="this.showSelector = false"></div>
        <div class="fieldSelectorBox" v-show="showSelector">
            <div class="fieldSelector">
                <div class="entityPanel selectorArea3 fieldSelectorActive">
                    <h3>組織</h3>
                    <ul>
                        <li :class="{'active': view.currentFieldId === f.field_id }"
                            v-for="f in view.fieldList" @click="doSelect(f)" :key="f.field_id">
                            {{ f.field_name }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <Apps :view="view" v-model:showApps="showApps" @update:ssoCheck="userInit"></Apps>
</template>

<script>
import Swal from "sweetalert2"
import {Apps, SSODialog} from 'gs-plugins'

export default {
    name: 'App',
    components: {
        Apps, SSODialog
    },
    setup() {
    },
    computed: {
        mainApps(){
            return window.appsLink.filter(x=>x.cat === 'main')
        },
        insideApps(){
            if (this.view.isLogin && this.view.currentUser.employee_id > 0)
                return window.appsLink.filter(x=>x.cat === 'inside')
            return []
        },
        otherApps(){
            if (this.view.isLogin)
                return window.appsLink.filter(x=>x.cat === 'other')
            return []
        },
        navData(){
            if (this.view.isLogin) return this.pageData['nav']
            return []
        },
        menuData(){
            if (this.navData){
                let s = this.navData.filter(x=>x.name === this.view.mainMenuName)
                if (s.length > 0) return s[0]["items"]
            }
            return []
        },
        fieldCount(){
            return this.view.selectFieldList.length
        },
        menuHeight() {
            return this.menuData.length * 42 + 'px'
        },
    },
    data() {
        return {
            isRouterAlive: true,
            isCollapsed: false,
            showSelector: false,
            showApps: false,
            mobileSiderShow:false,
            landingPage: "/predict/pv",
            appHover: null,
            menuRightSideWidth: "30px",
            edpCheckTime: 15,
            edpStatus: 'offline',
            edpText: '',
            edpSeconds: 0,
        }
    },
    unmounted() {
        window.removeEventListener('resize', this.view.handleWindowResize);
    },
    mounted() {
        this.view.handleWindowResize();
        window.addEventListener('resize', this.view.handleWindowResize);
        this.eventBus.on('afterFieldLoaded', this.afterFieldLoaded);
        this.view.wsConnect()
        window["alert"] = function (msg, timeout, allowOutsideClick) {
            if (typeof allowOutsideClick === "undefined") allowOutsideClick = true;
            Swal.close()
            setTimeout(function () {
                if (timeout !== undefined) {
                    Swal.fire({
                        title: "",
                        html: msg,
                        timer: timeout,
                        showConfirmButton: false,
                        allowOutsideClick: allowOutsideClick,
                    });
                } else {
                    if (msg) {
                        Swal.fire({
                            title: msg,
                        });
                    }
                }
            }, 100)
        }

        this.checkEDPAlive()
        setInterval(this.checkEDPAlive, 100)
        this.view.setPageInterval(this.checkEDPAlive, this.edpCheckTime)
    },
    methods: {
        checkEDPAlive() {
            // 每 0.1 秒檢查一次 EDP 狀態, 超過 15 秒沒有回應就視為斷線
            if ('edpInfo' in this.view.dataPool){
                let now = new Date()
                let last = new Date(this.view.dataPool['edpInfo']['datetime'])
                this.edpSeconds = ((now - last) / 1000).toFixed(1)
                if (now - last > this.edpCheckTime * 1000){
                    this.edpStatus = 'offline'
                    this.edpText = '目前此案場處於 離線 狀態'
                } else {
                    this.edpStatus = 'online'
                    this.edpText = '目前此案場處於 線上 狀態'
                }
            }else {
                this.edpStatus = 'offline'
                this.edpText = '目前此案場處於 離線 狀態'
            }
        },
        logoClick() {
            this.$router.push(this.landingPage)
        },
        toggleSelector(){
            this.showSelector = !this.showSelector
        },
        afterFieldLoaded(){
            let f = this.view.dataField.filter(x=>x.field_name === this.view.selectTarget)
            if (f.length > 0) this.doSelect(f[0])
        },
        mainMenuMouseOver(event, k){
            if (this.view.showSubMenu && this.view.isTablet) {
                this.mainMenuSelect(event, k)
            }
        },
        mainMenuSelect(event, k) {
            if (this.view.isTablet) {
                if (this.view.mainMenuName === k.name && event.type !== "mouseover") {
                    this.view.showSubMenu = !this.view.showSubMenu
                } else {
                    this.view.showSubMenu = true
                }
                this.view.mainMenuName = k.name

                const rect = event.target.getBoundingClientRect();
                const clickPositionFromLeft = event.clientX - rect.left;
                let rightSideWidth = window.innerWidth - event.clientX - (rect.width - clickPositionFromLeft);
                if (window.innerWidth - rightSideWidth - rect.width < 100)
                    rightSideWidth = window.innerWidth - 132;
                this.menuRightSideWidth = rightSideWidth + "px";
            } else {
                this.view.mainMenuName = k.name
                let i = k.items[0]
                this.$router.push(`/${i.dir}/${i.page}`)
            }
        },
        async userInit(data) {
            this.view.currentUser = data.userData;
            this.view.roleList = Object.freeze(data.roles);
            this.view.isLogin = data.isLogin;
            if (this.view.isLogin) {
                if (location.pathname === '' || location.pathname === '/') {
                    this.$router.push(this.landingPage)
                } else {
                    this.$router.push(location.pathname)
                }
                // this.view.loadFieldData().then(()=>{
                //     this.eventBus.emit('afterFieldLoaded')
                //     this.eventBus.emit('afterFieldListChanged')
                // })
            }
        },
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(() => {
                this.isRouterAlive = true
            });
            return false
        },
        getFormData(object) {
            let formData = new FormData();
            Object.keys(object).forEach(key => formData.append(key, object[key]));
            return formData;
        },
        doSelect(f){
            this.view.doSelect(f)
            this.showSelector = false
            this.$forceUpdate()
        },
    },
}
</script>

<style lang="stylus">
.toggle-split-panel-size-button
    position absolute;
    z-index 999
    top: 49px;
    left: 5px;
.mobileMenu
    transition: right 0.5s ease, height 0.3s ease;
    position: fixed;
    background: #fff;
    border: solid 1px #9999;
    z-index: 9999999;
    right: 30px;
    top: 42px;
    border-radius: 4px;
    width: 130px;
    overflow: hidden;
    .ivu-list-item
        height: 42px;
.debugMode
    color: rgb(91 109 9);
    font-size: 11px;
    position: absolute;
    left: 0;
    width: 100%;
    top: -1px;
    background: #fcf6d5b0;
    line-height: 10px;
.edpStatus
    font-size: 11px;
    color: #999;
    margin: 25px 5px;
    text-align: right;
    display: inline-block;
    float left;
    height 18px;
    padding 2px;
    border-radius 5px;
    background-color #ddd;
    line-height 10px;
    color: #fff;
    &.online
        background-color: #3ce33c;
        border solid 1px #7bcc7b;
    &.offline
        background-color: #c05858;
        border solid 1px #e08686;
.isTablet
    .edpStatus
        margin: 12px 0px;
</style>
